import React from 'react'
import Navbar from '../components/Navbar';
import HomeBody from '../components/Home/HomeBody';
import Footer from '../components/Footer';
import HeroeHeader from '../components/Home/HeroeHeader';
import Customers from '../components/Home/Customers/Customers'
//import HomeCarousel from '../components/Home/HomeCarousel';

const Home = () => {
  return (
    <>
        <Navbar />
        <HeroeHeader />
        <HomeBody />
        <Customers />
        <div className='flex items-center justify-center'>
          <Footer />
        </div>
    </>
  )
}

export default Home;