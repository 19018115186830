import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AboutContent from '../components/About/AboutContent'

const About = () => {
  return (
    <div >
       <Navbar />
     
         <AboutContent />
     
       <div className='flex items-center justify-center'>
          <Footer />
        </div>
    </div>
  )
}

export default About