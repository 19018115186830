import React from 'react'
import aboutHeader from '../../assets/about_header.jpg'

const AboutContent = () => {
  return (
    <div className='h-auto'>
        <div className='flex items-center justify-center w-full h-[300px] bg-cover ' style={{ backgroundImage: `url(${aboutHeader})` }}>
            <h1 className='text-white text-3xl font-bold'>ABOUT US.</h1>
        </div>
        <div className='flex flex-row items-center justify-center sm:py-20 py-5 sm:px-8 gap-2'>
            <div className='w-full flex flex-col items-center justify-center gap-8'>
                <p className='sm:max-w-[50%] max-w-[85%] sm:text-xl'>We are a Family Cleaning Business based in North Manly Since 2017, Doing Domestic Jobs, Strata Jobs, and Commercial Cleaning.
                </p>
                <p className='sm:max-w-[50%] max-w-[85%] sm:text-xl'>At our company, we pride ourselves on delivering exceptional quality cleaning services to our clients. We understand the importance of maintaining a clean and healthy environment, which is why we use only the best cleaning equipment and products to ensure that we get the job done right.</p>
                <p className='sm:max-w-[50%] max-w-[85%] sm:text-xl'>Our team of experienced and skilled cleaners is dedicated to providing the highest level of service to our clients. We are committed to working closely with you to understand your unique cleaning needs and preferences so that we can deliver a customized cleaning solution that meets your expectations.</p>
                <p className='sm:max-w-[50%] max-w-[85%] sm:text-xl'>Whether you need a one-time cleaning service or a regular cleaning schedule, our team is here to help. We offer flexible scheduling options to ensure that our services fit into your busy lifestyle. Additionally, our competitive pricing and commitment to quality make us the perfect choice for your cleaning needs.</p>
                <p className='sm:max-w-[50%] max-w-[85%] sm:text-xl'>Contact us today to learn more about our services and how we can help keep your home, office, or commercial space clean and healthy.</p>
            </div>

        </div>

    </div>
  )
}

export default AboutContent