import React from 'react'
import logo from '../assets/cleanning_logo.png'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai'
import { BiWorld } from 'react-icons/bi'
import { BsWhatsapp, BsFacebook } from 'react-icons/bs'


const Footer = () => {



  return (
    <footer className='flex flex-col bg-white' style={{ borderTop: '1px solid lightGray'}}>
      <div className='sm:p-16 p-6 flex sm:flex-row flex-col items-center justify-center sm:w-[85em] sm:gap-42 gap-4'>  
        <img src={logo} alt="cleanning_logo" className='sm:w-40 w-32 sm:h-28 h-20 sm:mr-10' />

        <div className='w-full flex flex-row items-start gap-16 sm:ml-20'>
          <div className='flex flex-col items-start gap-2'>
            <div className='sm:w-52 w-full h-full flex items-center sm:justify-start justify-center sm:ml-7'>
              <p className='font-bold text-lg text-cyan-500'>Contact Us</p>
            </div>
            <div className='flex flex-row gap-2 justify-center sm:ml-0 ml-10'>
              <AiOutlinePhone size={25} className='text-cyan-500'/>
              <p className='font-semibold text-cyan-500'>(+61) 0478 024 363</p>
            </div>
            <div className='flex flex-row gap-2 justify-center sm:ml-0 ml-10'>
              <AiOutlinePhone size={25} className='text-cyan-500'/>
              <p className='font-semibold text-cyan-500'>(+61) 0422 135 989</p>
            </div>
            <div className='flex flex-row gap-2 justify-center'>
              <AiOutlineMail size={25} className='text-cyan-500'/> 
              <p className='font-semibold text-cyan-500'>northmanlycleaners@gmail.com</p>
            </div>
          </div>
        </div>

        <div className='w-full flex flex-row items-start gap-16 sm:ml-20'>
          <div className=' flex flex-col items-start gap-3'>
            <div className='sm:w-52 w-full h-full flex items-center sm:justify-start justify-center sm:px-0 px-20 sm:ml-8'>
              <p className='font-bold text-lg text-cyan-500'>Location</p>
            </div>
            <div className='flex flex-row gap-2 justify-center'>
              <BiWorld size={25} className='text-cyan-500'/>
              <div className='flex sm:flex-col gap-2'>
                <p className='font-semibold text-cyan-500'>Australia,</p>
                <p className='font-semibold text-cyan-500'>North Manly,</p>
                <p className='font-semibold text-cyan-500'>NSW</p>
              </div>
            </div>
      
          </div>
        </div>

      
        <div className='w-full flex flex-row items-center justify-center gap-6'>
          <motion.div whileHover={{ scale: 1.1 }}>
          <Link to="https://api.whatsapp.com/send?phone=61416824967" target="_blank">
            <BsWhatsapp size={50} className="text-green-500 hover:text-green-600" />
          </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
          <a href="https://www.facebook.com/northmanlycleaners" target='blank'>
            <BsFacebook size={55} className='text-blue-500 hover:text-blue-600'/> {/* envolver en una etiqueta a para redirigir */}
          </a>
          </motion.div>
        </div>


        </div>
        <div style={{ borderTop: '1px solid lightGray'}} className='p-2 flex items-center justify-center'>
            <p className='text-cyan-500'>© 2023. All Rights reserved by <a href='https://www.linkedin.com/in/tomas-bartoldi-395818242/' target='blank'>Tomás Bartoldi</a></p>
        </div>
        
    </footer>
  )
}

export default Footer