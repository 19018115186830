import React from 'react'
import img2 from '../../../assets/customers_logos/cleangreenstrata.png'
import img3 from '../../../assets/customers_logos/etchrealestate.png'
import img4 from '../../../assets/customers_logos/iga.png'
import img5 from '../../../assets/customers_logos/mjt.png'
import img6 from '../../../assets/customers_logos/vdgservices.png'
import img7 from '../../../assets/customers_logos/day_to_day.png'
import img8 from '../../../assets/customers_logos/strata_one.png'
import img9 from '../../../assets/customers_logos/belle_property.png'


const Customers = () => {
  return (
    <div className='flex flex-col items-center justify-center py-20 gap-20'>

        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-[80%]">
            <h1 className="text-cyan-500 text-3xl font-bold">Our clients</h1>
          </div>
          <hr className="border-[1.5px] border-gray-300 w-[80%] "/>
        </div>

        <div className='flex flex-col items-center justify-center gap-10'>
        <div className='flex sm:flex-row flex-col items-center justify-center gap-10'>
            <img src={img3} alt="logo1" className='w-40' />
            <img src={img4} alt="logo1" className='w-40' />
            <img src={img2} alt="logo1" />
            <img src={img5} alt="logo1" className='w-40' />
          </div>
          <div className='flex sm:flex-row flex-col items-center justify-center gap-10'>
            <img src={img6} alt="logo1" />   
            <img src={img7} alt="logo1" className='w-60'/>   
            <img src={img8} alt="logo1" className='w-60'/>   
            <img src={img9} alt="logo1" className='w-60'/>   
          </div>
        </div>

    </div>
  )
}

export default Customers