import React, { useState } from 'react'
import services from './Services'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { motion } from 'framer-motion'
import TrackVisibility from 'react-on-screen';
import servicesHeroe from '../../assets/services_heroe.jpg'


const ServicesCards = () => {

  const [ isOpen, setIsOpen ] = useState(Array(services.length).fill(false));

  const handleClick = (id) => {
    setIsOpen(prevState => {
      const newState = [...prevState];
      newState[id] = !newState[id];
      return newState;
    });
  }
  
  return (
    <TrackVisibility once>
    {({ isVisible }) => isVisible ?
    <motion.div className='flex flex-col gap-10 bg-white' id='servicesCards '>
        <div className='flex flex-col items-center justify-center w-full h-[300px] bg-cover ' style={{ backgroundImage: `url(${servicesHeroe})` }}>
          <h1 className='text-white text-3xl font-bold'>SERVICES.</h1>
          <p className='text-white'>What we do</p>
        </div>
    <motion.div className='flex flex-wrap gap-16 sm:p-12 p-3 items-center justify-center'>
    {
      services.map(card => {
        return (
            <motion.div transition={{ layout: {duration: 1, type: 'spring'}, duration: 1 }} layout key={card.id} 
            className=' bg-cyan-500 flex flex-col gap-10 items-center justify-center sm:w-[450px] w-[300px] p-6 shadow-xl rounded-xl'
            >
                <motion.div layout='position' className='w-full flex flex-row gap-2 items-center justify-center'>
                  <motion.p layout='position' 
                  initial={{ opacity: 0 }} 
                  animate={{ opacity: 1 }} 
                  transition={{ delay: 0.3, duration: 2, type: 'spring', stiffness: 120 }}
                  className='border-4 border-white rounded-full p-3 '>{card.image}</motion.p>

                  <motion.div className='w-full flex flex-col justify-end'
                    initial={{ y: -70 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.2, type: 'spring', stiffness: 120 }}
                  >
                    <motion.div className='flex justify-end' 

                    >
                      <motion.h1 layout='position' className='text-xl text-white font-semibold'>
                        {card.name}
                      </motion.h1>
                      </motion.div>
                        <hr className='border-white'/>
                    </motion.div>
                </motion.div>

                
                {isOpen[card.id] &&(
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1}} >
                  <p className='text-white'> {card.description} </p>  
                </motion.div>
                )}
                
                <motion.div className='w-full flex justify-end'>
                  <motion.button 
                    whileHover={{ scale: 1.2 }}
                    onClick={() => handleClick(card.id)}  
                  >
                      <AiOutlinePlusCircle size={25} className='text-white' />
                  </motion.button>
                </motion.div>

            </motion.div>
        )
    })
}
</motion.div>
</motion.div>
: ""}
</TrackVisibility>
  )
}

export default ServicesCards;