import React from 'react'
import ServicesCards from './ServicesCards'



import { motion } from 'framer-motion'

const HomeBody = () => {


  return (
    <motion.div
     className='sm:py-8 py-10'>
      <ServicesCards />
    </motion.div>
  )
}

export default HomeBody