import React, { useRef } from 'react'
import heroeImgContact from '../../assets/cleanning_contact_heroe.jpg'
import { motion } from 'framer-motion'
import TrackVisibility from 'react-on-screen';
import contactHeroe from '../../assets/contact_heroe.jpg'
import emailjs from '@emailjs/browser'
import SweetAlert from 'sweetalert2';

const ContactForm = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wkxhgyn', 'template_xfsdwgh', form.current, 'SIJtNs1-X_zHRXt3h')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      SweetAlert.fire({
        title: 'Success!',
        text: 'Your message was sent successfully.',
         icon: 'success',
        confirmButtonText: 'Finish',
      });

      setTimeout(()=> {
        // Agrega el redirect a '/formularios' usando la función 'window.location.replace'
        window.location.replace('/');
      }, 1000)
  };




  return (
<TrackVisibility once>
    {({ isVisible }) => isVisible ?
    <form className='flex flex-col gap-4' ref={form} onSubmit={sendEmail}>
        <div className='flex items-center justify-center w-full h-[300px] bg-cover ' style={{ backgroundImage: `url(${contactHeroe})` }}>
          <h1 className='text-white text-3xl font-bold'>CONTACT.</h1>
        </div>

      <motion.div className='flex sm:flex-row flex-col px-10 py-2 gap-4'>
        <motion.div className='sm:w-1/2 flex flex-col gap-4 items-center justify-center sm:py-20' initial={{ x: -1000, y: 0, scale: 1, rotate: 0 }} animate={{ x: 0, y: 0, scale: 1, rotate: 0 }} transition={{ duration: 1 }}>

            <div className='flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <label for="user_name" className='text-cyan-500'>Your Name</label>
                <input name="user_name" type='text' className='border-2 border-cyan-500 sm:w-[500px]  rounded-lg' />
              </div>
              <div className='flex flex-col gap-2'>
                <label for="user_email" className='text-cyan-500'>Email</label>
                <input name="user_email" type='email' className='border-2 border-cyan-500 sm:w-[500px]  rounded-lg' />
              </div>
            </div>
            <div className='flex flex-col gap-2'>
               <label for="message" className='text-cyan-500'>Message</label>
               <textarea name="message" rows='7' placeholder='Type your message here'  className='border-2 border-cyan-500 sm:w-[500px] w-[250px] p-2 rounded-lg'  />  
            </div>

           <button type='submit' target='blank' className=' p-2 bg-cyan-500 text-white font-bold sm:w-[500px] w-[250px] rounded-lg'>Submit</button>
        </motion.div>

        <motion.div className='sm:w-1/2 flex items-center justify-center' initial={{ x: 1000, y: 0, scale: 1, rotate: 0 }} animate={{ x: 0, y: 0, scale: 1, rotate: 0 }} transition={{ duration: 1 }}>
          <img src={heroeImgContact} alt="heroe_image" className='rounded-full sm: w-[500px] ' />
        </motion.div>
      </motion.div>
        
    </form>
    : ""}
    </TrackVisibility>
  )
}

export default ContactForm