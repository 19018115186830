import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ServicesCards from './../components/Services/ServicesCards';

const Services = () => {
  return (
    <div>
       <Navbar />

        <ServicesCards />   
       <div className='flex items-center justify-center sm:mt-40'>
        <Footer />
       </div>
    </div>
  )
}

export default Services