import React from 'react'
import heroImg from '../../assets/cleanning_heroe.jpg'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


const HeroeHeader = () => {
  return (
    <motion.div className='w-full sm:h-[80vh] flex sm:flex-row flex-col items-center justify-center p-4'
    id='heroeHeader'
    initial={{ opacity: 0, y: -100 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1 }}
    >
       <div className='sm:w-1/2 h-full flex items-center justify-center'>
          <img src={heroImg} alt="hero_image" className='xs:hidden' style={{ width: '500px' }} />
       </div>
       <div className='sm:w-1/2 h-full flex flex-col gap-4 justify-center pl-10'>
          <h1 className='text-cyan-500 font-bold sm:text-4xl text-xl sm:max-w-[400px] max-w-[200px] '>Experience the power of a pristine space</h1>
          <p className='sm:max-w-[620px] max-w-[250px] '>Our company is a professional cleaning service located in Manly, Australia, offering a wide range of cleaning services for homes, offices, restaurants, supermarkets, recycling machines, and AirBnB accommodations. We take pride in delivering exceptional quality cleaning services using the best equipment and products. Our team of experienced cleaners is dedicated to providing customized cleaning solutions that meet our clients' needs. We offer flexible scheduling options and competitive pricing, and we are committed to keeping your space clean and healthy. Contact us today to learn more about our services.</p>
          <div className='w-full'>
            <Link to='/about'>
              <motion.button className='p-3 bg-cyan-500 rounded-xl text-white shadow-xl'
               whileHover={{ scale: 1.2 }}
              >
                Get to know us 
              </motion.button>
            </Link>
            
          </div>
       </div>
    </motion.div>
  )
}

export default HeroeHeader