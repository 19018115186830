import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { BsFillHouseAddFill } from 'react-icons/bs'
import { FiShoppingCart } from 'react-icons/fi'
//import { BiBuildingHouse } from 'react-icons/bi'
//import { FaAirbnb } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import TrackVisibility from 'react-on-screen';
//import HomeServCar from './ServicesCarousels/HomeServCar';
//import AirbnbServCar from './ServicesCarousels/AirbnbServCar';


const ServicesCards = () => {
  return (
    <TrackVisibility once>
      {({ isVisible }) => isVisible ?
      
    <div className='flex flex-col items-center justify-center gap-16'>
  <div className="w-full flex flex-col items-center justify-center">
    <div className="w-[80%]">
      <h1 className="text-cyan-500 text-3xl font-bold">Services</h1>
    </div>
    <hr className="border-[1.5px] border-gray-300 w-[80%] "/>
    <div className="w-[80%] flex justify-end">
      <p className="text-gray-300 text-lg">What we do</p>
    </div>
  </div>

{/* HERE GOES SERVICES CARDS WITH IMAGES */}

<motion.div className='flex sm:flex-row flex-col gap-32'>
       <motion.div
        className='bg-cyan-600 sm:w-72 w-60 sm:h-72 h-60 rounded-2xl flex flex-col items-center justify-center gap-4 shadow-slate-400 shadow-xl'
        initial={{ scale: 0.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1 }}
        >
        <div className='border-4 border-white rounded-full p-3 flex items-center justify-center'>
          <AiOutlineHome size={40} className='text-white' />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h5 className='text-white'>Home's Cleaning</h5>
          <h5 className='text-white'>Services</h5>
        </div>
       </motion.div>

       <motion.div 
          className='bg-cyan-600 sm:w-72 w-60 sm:h-72 h-60 rounded-2xl flex flex-col items-center justify-center gap-4 shadow-slate-400 shadow-xl'
          initial={{ scale: 0.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
       >
        <div className='border-4 border-white rounded-full p-2 flex items-center justify-center'>
          <BsFillHouseAddFill size={50} className='text-white' />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h5 className='text-white'>Deep Cleaning</h5>
          <h5 className='text-white'>Services</h5>
        </div>
       </motion.div>

       <motion.div 
        className='bg-cyan-600 sm:w-72 w-60 sm:h-72 h-60 rounded-2xl flex flex-col items-center justify-center gap-4 shadow-slate-400 shadow-xl'
        initial={{ scale: 0.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1 }}
        >
        <div className='border-4 border-white rounded-full p-3 flex items-center justify-center'>
          <FiShoppingCart size={40} className='text-white' />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h5 className='text-white'>Supermarket Cleaning</h5>
          <h5 className='text-white'>Services</h5>
        </div>
       </motion.div>

       
       </motion.div>



       <Link to='/services' className='no-underline'>
            <motion.div 
              className='text-cyan-500 flex flex-row items-center justify-center gap-4 hover:text-emerald-500'
              initial={{ scale: 0.2 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1 }}
            >
              <motion.button whileHover={{ scale: 1.1 }}   className='p-3 bg-cyan-500 rounded-xl text-white shadow-xl'>
                 <h5>Learn more</h5> 
              </motion.button >
            </motion.div>
       </Link>
    </div>
    
    : ""}
    </TrackVisibility>
  )
}

export default ServicesCards


/* 

      <motion.div className='flex sm:flex-row flex-col gap-32'>
       <motion.div
        className='bg-cyan-600 sm:w-72 w-60 sm:h-72 h-60 rounded-2xl flex flex-col items-center justify-center gap-4 shadow-slate-400 shadow-xl'
        initial={{ scale: 0.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1 }}
        >
        <div className='border-4 border-white rounded-full p-3 flex items-center justify-center'>
          <AiOutlineHome size={40} className='text-white' />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h5 className='text-white'>Home's Cleaning</h5>
          <h5 className='text-white'>Services</h5>
        </div>
       </motion.div>

       <motion.div 
          className='bg-cyan-600 sm:w-72 w-60 sm:h-72 h-60 rounded-2xl flex flex-col items-center justify-center gap-4 shadow-slate-400 shadow-xl'
          initial={{ scale: 0.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
       >
        <div className='border-4 border-white rounded-full p-2 flex items-center justify-center'>
          <BsFillHouseAddFill size={50} className='text-white' />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h5 className='text-white'>Deep Cleaning</h5>
          <h5 className='text-white'>Services</h5>
        </div>
       </motion.div>

       <motion.div 
        className='bg-cyan-600 sm:w-72 w-60 sm:h-72 h-60 rounded-2xl flex flex-col items-center justify-center gap-4 shadow-slate-400 shadow-xl'
        initial={{ scale: 0.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1 }}
        >
        <div className='border-4 border-white rounded-full p-3 flex items-center justify-center'>
          <FiShoppingCart size={40} className='text-white' />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h5 className='text-white'>Supermarket Cleaning</h5>
          <h5 className='text-white'>Services</h5>
        </div>
       </motion.div>

       
       </motion.div>

*/