import React, { useState } from 'react'
import logo from '../assets/cleanning_logo.png'
import { Link } from 'react-router-dom';
import NavbarModal from './NavbarModal';
import { GiHamburgerMenu  } from 'react-icons/gi'
import { motion } from 'framer-motion'


const Navbar = () => {

  const [toggle, setToggle] = useState(false)
  const [ showModal, setShowModal] = useState(false)




  const handleClick = (e) => {
      setToggle(!toggle)
      setShowModal(!showModal)
  }


  return (
    <nav className='sm:py-6 py-4 sm:px-12 px-8 flex flex-row items-center justify-between bg-white'>
        <Link to='/'>
          <motion.img src={logo} whileHover={{ scale: 1.1 }} alt="cleanning_logo" className='sm:w-32 w-20 sm:h-20 h-12' />
        </Link>
        <div className='flex items-center justify-center'>
            <ul className='sm:flex sm:flex-row sm:gap-10 hidden'>
                <Link to='/' className='no-underline'>
                  <motion.li whileHover={{ scale: 1.3 }} className='sm:text-md text-cyan-500 font-semibold'>Home</motion.li>
                </Link>
                <Link to='/about' className='no-underline'>
                  <motion.li whileHover={{ scale: 1.3 }} className='sm:text-md text-cyan-500 font-semibold'>About Us</motion.li>
                </Link>
                <Link to='/services' className='no-underline'>
                  <motion.li whileHover={{ scale: 1.3 }} className='sm:text-md text-cyan-500 font-semibold'>Services</motion.li>
                </Link>
                <Link to='/contact' className='no-underline'>
                  <motion.li whileHover={{ scale: 1.3 }} className='sm:text-md text-cyan-500 font-semibold'>Contact</motion.li>
                </Link>
            </ul>
            <div className='sm:hidden flex flex-1 justify-end items-center'>
               { 
                 toggle && showModal ? 
                 <NavbarModal showModal={showModal} setShowModal={setShowModal} toggle={toggle} setToggle={setToggle} /> :
                 (
                  <button onClick={handleClick}>
                    <GiHamburgerMenu size={25} />
                  </button>
                )
               }
            </div>       
        </div>
        
    </nav>
  )
}

export default Navbar;