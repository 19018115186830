import React from 'react'
import Navbar from '../components/Navbar'
import ContactForm from '../components/ContactForm/ContactForm'
import Footer from '../components/Footer'

const Contact = () => {
  return (
    <div>
        <Navbar />
        <ContactForm />
        <div className='flex items-center justify-center'>
          <Footer />
        </div>
    </div>
  )
}

export default Contact