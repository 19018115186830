import React from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '../src/pages/Home.jsx'
import Services from "./pages/Services.jsx";
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
